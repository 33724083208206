//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuItems: [],
      logo: '',
      mobileLogo: '',
      logoLink: '',
      socialLinks: [],
      mobileMenuOpen: false,
    }
  },
  computed: {
    leftMenuItems() {
      if (this.menuItems.length <= 3) {
        return this.menuItems
      }
      return this.menuItems.slice(0, 3)
    },
    rightMenuItems() {
      if (this.menuItems.length <= 3) {
        return []
      }
      return this.menuItems.slice(3)
    },
  },
  async created() {
    const content = (await this.$axios.get(`/contents/header`)).data

    this.menuItems = content.details?.section
    this.socialLinks = content.details?.socials
    this.logo = content.details?.logo?.fieldValue.fullFileUrl
    this.mobileLogo = content.details?.mobile_logo?.fieldValue.fullFileUrl
    this.logoLink = content.details?.logo_link?.fieldValue.value
  },
  methods: {
    closeNav(e) {
      this.mobileMenuOpen = false
    },
    openNav() {
      this.mobileMenuOpen = true
    },
  },
}
